const defaultOptions = {
	showOnLoad: false,
	classes: '',
	markup: '',
	callback: false
};

const s = {
	primary: 'button--primary',
	secondary: 'button--secondary',
	outline: 'button--outline',
}

export type ButtonVariation = {
	variation: 'primary' | 'secondary' | 'outline';
}

export interface IDialogOptions {
	showOnLoad?: boolean;
	classes?: string;
	markup?: string;
	redirectUrl?: string;
	title?: string;
	message?: string;
}

export default abstract class DialogViewBase {
	options: any;
	el: HTMLElement;

	constructor(options: IDialogOptions) {
		const templateNode = document.importNode((document.querySelector('#dialog-template') as any).content, true).querySelector(".dialog");
		this.el = templateNode.cloneNode(true);	
		this.options = options || defaultOptions;

		if(typeof this.options.showOnLoad !== "boolean") { this.options.showOnLoad = true; }
		this.options.classes = (this.options.classes || "").split(" ");
			
		if(this.options.showOnLoad) { this.show(); }

		this.onWindowKeyUp = this.onWindowKeyUp.bind(this);
		this.remove = this.remove.bind(this);

		const overlay = this.el.querySelector('.dialog-overlay');
		const close = this.el.querySelector('.dialog-close');

		overlay && overlay.addEventListener("click", this.remove);
		close && close.addEventListener("click", this.remove);	
	}

	show() {	
		document.body.appendChild(this.el);
		this.el.classList.add("active");
		this.options.classes.map((str: string) => str.length > 0 && this.el.classList.add(str));
		window.addEventListener("keyup", this.onWindowKeyUp, false);
		window.document.body.style.overflow = 'hidden';

		setTimeout(() => {
			this.el.classList.add("show-dialog");
		}, 0);
	}

	remove() {
		window.document.body.removeAttribute('style');
		document.querySelector('body')?.classList.remove('hide-book-new-trip-btn');
		
		if(this.options.onReject){
			this.options.onReject();
		}

		if(this.options.redirectUrl) {
			if(this.options.redirectUrl.length > 0) {
				window.location = this.options.redirectUrl;
			}
		}
		
		this.el.classList.remove('show-dialog');

		setTimeout(() => {
			window.document.body.removeAttribute('style');
			this.el.parentNode && this.el.parentNode.removeChild(this.el);
			window.removeEventListener("keyup", this.onWindowKeyUp, false);
		}, 300);
	}

	onWindowKeyUp(event: KeyboardEvent) {
		if(event.key === 'Escape') { 
			this.remove && this.remove();
		}		
	}

	computeButtonVariation({ variation }: ButtonVariation) {
		switch (variation) {
		  case 'primary':
			return `${s.primary}`;
		  case 'secondary':
			return `${s.secondary}`;
		  case 'outline':
			return `${s.outline}`;
		}
	}

	render(): any {
		return null;
	}
}