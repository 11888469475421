class Notification {
  el: Element;
	notifications: string[];
  constructor() {
      this.el = document.querySelector('.notification-list') || document.body;
      this.notifications = [];
  }

  add(item: string | undefined) {
      if(this.notifications.length > 0) {
        return;
      }
      
      this.notifications.push(item || 'Something went wrong'); 
      this.render();

      setTimeout(() => {
          this.clear();
      }, 3000);

  }

  clear() {
      const el = this.el.children[0];
      el.classList.add('start-exiting');

      const onTransitionEnd = () => {
          el.removeEventListener('animationend', onTransitionEnd);
          this.notifications = []; 
          this.render();
      }

      el.addEventListener('animationend', onTransitionEnd);
  }
  
  /**
   * React to state changes and render the component's HTML
   *
   * @returns {void}
   */
  render() {
      let emoji = '⛔';

      // Loop the items and generate a list of elements
      this.el.innerHTML = this.notifications.map(item => {
          return `
              <li class="notification-list-item">${item} ${emoji}</li>
          `
      }).join('');
  }
}

export default (new Notification);
