import DialogView from '../dialogs/dialog-view';
import AddPassDialog from '../dialogs/add-pass-dialog';
import AddBookingDialog from '../dialogs/add-booking-dialog';
import { formattedDate, replaceTerm, sleep, t, windowRef } from '../utils/utils';
import EditBookingDialog from '../dialogs/edit-booking-dialog';
import ViewTicketsDialog from '../dialogs/view-tickets-dialog';
import { IBooking } from '../templates/list-items';
import { cancelBooking, deletePass } from '../api/umbraco-api';
import { IPassFullModel, isFetchErrorResponse } from '../types/main';
import Notification from '../components/notification';
import PassInfoDialog from '../dialogs/pass-info-dialog';

windowRef.prebookDialogViews = {};

export const verifyPass = (title: string, message: string) => {
  const key = addNewPass.name;

  if (windowRef.prebookDialogViews[key]) {
    return;
  }

  const dialog = new DialogView({ 
    title,
    message,
    onConfirm: () => {
      windowRef.location.reload();
     },
    onReject: () => {
      delete windowRef.prebookDialogViews[key];
      windowRef.location.reload();
    },
    // primaryButtonVariation: { variation: 'primary' },
    secondaryButtonVariation: { variation: 'secondary' },
    // primaryButtonText: t.button.addAdditionalPass,
    secondaryButtonText: t.button.back,
    classes: 'curtain',
  });

  dialog.render();
  windowRef.prebookDialogViews[key] = dialog;
}

export const registerYourPass = () => {
  const key = registerYourPass.name;

  closeBooking();

  if (windowRef.prebookDialogViews[key]) {
    return;
  }

  const dialog = new AddPassDialog({ 
    title: t.headline.registerPass,
    message: t.description.registerPass,
    onConfirm: () => {},
    onReject: () => { 
      delete windowRef.prebookDialogViews[key];
    },
    classes: 'curtain',
  });
  
  dialog.render();
  windowRef.prebookDialogViews[key] = dialog;
}

export const addNewPass = () => {
  const key = addNewPass.name;

  if (windowRef.prebookDialogViews[key]) {
    return;
  }

  const dialog = new DialogView({ 
      title: t.button.addNewPass,
      message: t.description.addNewPass,
      onConfirm: () => registerYourPass(),
      onReject: () => { 
        delete windowRef.prebookDialogViews[key];
       },
      classes: 'curtain',
      primaryButtonVariation: { variation: 'primary' },
      secondaryButtonVariation: { variation: 'secondary' },
      primaryButtonText: t.button.confirm,
      secondaryButtonText: t.button.cancel,
    });

    dialog.render();
    windowRef.prebookDialogViews[key] = dialog;
}

const cancelTripSuccess = (booking: IBooking) => {
  const key = cancelTripSuccess.name;

  if (windowRef.prebookDialogViews[key]) {
    return;
  }

  const dialog = new DialogView(
    {
      title: replaceTerm(t.headline.cancelBookingConfirmation, {
        attractionName: booking.attraction.attractionName
      }),
      message: t.description.booking,
      booking,
      onConfirm: () => {
        windowRef.location.reload();
      },
      onReject: () => {
        delete windowRef.prebookDialogViews[key];
        windowRef.location.reload();
      },
      secondaryButtonVariation: { variation: 'primary' },
      secondaryButtonText: t.button.bookingOverView,
      classes: 'curtain',
    }
  );

  dialog.render();
  windowRef.prebookDialogViews[key] = dialog;
}

export const cancelTripConfirmation = (booking: IBooking) => {
  const key = cancelTripConfirmation.name;

  if (windowRef.prebookDialogViews[key]) {
    return;
  }

  const dialog = new DialogView(
    {
      title: t.headline.cancelBooking,
      message: t.description.cancelBooking,
      booking,
      onConfirm: async () => {
        await sleep(400);

        if(!booking.bookingId) {
          return;
        }

        const response = await cancelBooking(booking.bookingId);

        if (response.errorMessage) {
          // Failed to delete booking
          return response;
        }

        cancelTripSuccess(booking);
      },
      onReject: () => {
        delete windowRef.prebookDialogViews[key];
      },
      primaryButtonVariation: { variation: 'primary' },
      secondaryButtonVariation: { variation: 'secondary' },
      primaryButtonText: t.button.cancelBookingConfirm || 'Cancel Booking',
      secondaryButtonText: t.button.cancelBookingCancel || 'Back',
      classes: 'curtain',
    }
  );

  dialog.render();
  windowRef.prebookDialogViews[key] = dialog;
}

export const editBookingConfirmation = (booking: IBooking) => {
  const key = editBookingConfirmation.name;

  if (windowRef.prebookDialogViews[key]) {
    return;
  }

  const dialog = new DialogView(
    {
      title: t.headline.editBooking,
      booking,
      isEditMode: true,
      onConfirm: () => {},
      onReject: () => {
        delete windowRef.prebookDialogViews[key];
      },
      secondaryButtonVariation: { variation: 'secondary' },
      secondaryButtonText: t.button.cancel,
      classes: 'curtain',
    }
  );

  dialog.render();
  windowRef.prebookDialogViews[key] = dialog;
}

export const editBooking = (booking: IBooking, startAtStep: number) => {
  const key = editBooking.name;

  if (windowRef.prebookDialogViews[key]) {
    return;
  }

  const dialog = new EditBookingDialog(
    booking,
    {
      startAtStep,
      onConfirm: () => {},
      onReject: () => { 
        delete windowRef.prebookDialogViews[key];
      },
      classes: 'alternate-close-button-color',
    }
  );

  dialog.render();
  windowRef.prebookDialogViews[key] = dialog;
}

const closeEditBooking = () => {
  const bookingFlow = windowRef.prebookDialogViews["editBooking"];
  if(bookingFlow) {
    bookingFlow.remove();
    delete windowRef.prebookDialogViews["editBooking"];
  }
}

const closeEditBookingConfirmation = () => {
  const bookingFlow = windowRef.prebookDialogViews["editBookingConfirmation"];
  if(bookingFlow) {
    bookingFlow.remove();
    delete windowRef.prebookDialogViews["editBookingConfirmation"];
  }
}

export const startBooking = () => {
  const key = startBooking.name;

  if (windowRef.prebookDialogViews[key]) {
    return;
  }

  const dialog = new AddBookingDialog({
    onConfirm: () => {},
    onReject: () => { 
      delete windowRef.prebookDialogViews[key];
     },
    classes: 'alternate-close-button-color',
  });

  dialog.render();
  windowRef.prebookDialogViews[key] = dialog;

  //Add event listener to hide 'book new trip' button
  const bookNewBtn = document.querySelector('.book-trip-button');
  const body = document.querySelector('body');

  if(bookNewBtn != null) {
    bookNewBtn.addEventListener('click', () => {
      if(body != null) {
        body.classList.add('hide-book-new-trip-btn')
      }
    });
  }
}

const closeBooking = () => {
  //Add event listener to show 'book new trip' button that's been hidden
  const closeBtn = document.querySelector('.alternate-close-button-color .dialog-close');
  const body = document.querySelector('body');
  if(closeBtn != null) {
    closeBtn.addEventListener('click', () => {
      if(body != null) {
        body.classList.remove('hide-book-new-trip-btn')
      }
    })
  }

  const bookingFlow = windowRef.prebookDialogViews["startBooking"];
  if(bookingFlow) {
    bookingFlow.remove();
    delete windowRef.prebookDialogViews["startBooking"];
  }
}

export const bookingSuccessful = (booking: IBooking) => {
  const key = bookingSuccessful.name;

  closeBooking();
  closeEditBooking();
  closeEditBookingConfirmation();

  if (windowRef.prebookDialogViews[key]) {
    return;
  }

  const dialog = new DialogView(
    {
      title: replaceTerm(t.headline.createBooking, {
        attractionName: booking.attraction.attractionName
      }),
      message: t.description.booking,
      booking,
      confirmHandlerWithoutClosingDialog: true,
      onConfirm: () => {
        viewTickets(booking);
      },
      onReject: () => {
        delete windowRef.prebookDialogViews[key];
        windowRef.location.reload();
      },
      primaryButtonVariation: { variation: 'secondary' },
      secondaryButtonVariation: { variation: 'primary' },
      primaryButtonText: t.button.viewTickets,
      secondaryButtonText: t.button.bookingOverView,
      classes: 'curtain',
    }
  );

  dialog.render();
  windowRef.prebookDialogViews[key] = dialog;
}

const closeBookingSuccessful = () => {
  const bookingFlow = windowRef.prebookDialogViews["bookingSuccessful"];
  if(bookingFlow) {
    bookingFlow.remove();
    delete windowRef.prebookDialogViews["bookingSuccessful"];
  }
}

export const viewTickets = (booking: IBooking) => {
  const key = viewTickets.name;

  if (windowRef.prebookDialogViews[key]) {
      return;
  }

    const dialog = new ViewTicketsDialog(booking.bookingId || '', {
      title:  replaceTerm(t.headline.tickets, {
        attractionName: booking.attraction.attractionName,
        date: formattedDate(booking.startDate)
      }),
      onConfirm: async () => {
          delete windowRef.prebookDialogViews[key];
          closeBookingSuccessful();
      },
      onReject: () => {
          delete windowRef.prebookDialogViews[key];
      },
      primaryButtonVariation: { variation: 'primary' },
      secondaryButtonVariation: { variation: 'secondary' },
      classes: '',
  });

  dialog.render();
  windowRef.prebookDialogViews[key] = dialog;
}

export const deletePassConfirmation = (pass: IPassFullModel) => {
  const key = deletePassConfirmation.name;

  if (windowRef.prebookDialogViews[key]) {
    return;
  }

  const dialog = new DialogView(
    {
      title: t.headline.cancelPass,
      pass,
      onConfirm: async () => {
        await sleep(500);

        if(!pass.barCode) {
          Notification.add('Pass barcode is missing');
          return;
        }

        try {	
          const response = await deletePass(pass.barCode);
    
          if (isFetchErrorResponse(response)) {
            throw response;
          }
        
          windowRef.location.reload();
        } catch (error) {
          Notification.add(error.errorMessage || error.detail);
        }

      },
      onReject: () => {
        delete windowRef.prebookDialogViews[key];
      },
      primaryButtonVariation: { variation: 'primary' },
      secondaryButtonVariation: { variation: 'secondary' },
      primaryButtonText: t.button.confirm,
      secondaryButtonText: t.button.cancel,
      classes: 'curtain',
    }
  );

  dialog.render();
  windowRef.prebookDialogViews[key] = dialog;
}

export const passInfo = (pass: IPassFullModel) => {
  const key = passInfo.name;

  if (windowRef.prebookDialogViews[key]) {
    return;
  }

  const dialog = new PassInfoDialog({ 
      title: pass.nickname,
      message: pass.description,
      pass,
      onConfirm: () => {},
      onReject: () => { 
        delete windowRef.prebookDialogViews[key];
      },
      classes: 'curtain',
      secondaryButtonVariation: { variation: 'secondary' },
      secondaryButtonText: t.button.ok || 'Ok',
    });

    dialog.render();
    windowRef.prebookDialogViews[key] = dialog;
}