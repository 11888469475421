/**
 * creates the HTML element specified by name (tagName), with the desired classes
 * @param {HTMLElement} name tagName
 * @param {string} attribs class or classes
 */
 export const makeElement = (name: any, ...attribs: any[]) => {
	let elem: any;
	if ((attribs && attribs[0] && (attribs[0]).xmlns) || (name && name.toLowerCase() === 'svg')) {
		const attrNs = (attribs[0]).xmlns;
		const ns = typeof attrNs === 'string' ? attrNs : 'http://www.w3.org/2000/svg';
		elem = document.createElementNS(ns, name);
		elem.setAttribute('xmlns', attrNs);
	} else {
		elem = document.createElement(name);
	}

	attribs.slice(0).forEach((attribute: string | Node | Array<string> | any) => {
		if (typeof (attribute) === 'string') {
			attribute.split(' ').forEach(className => elem.classList.add(className));
		} else if (attribute instanceof Node || attribute instanceof Array) {
			[].concat(attribute as never[]).forEach((node: string) => {
				if (node) elem.appendChild((typeof node === 'string' || typeof node === 'number') ? document.createTextNode(node.toString()) : node);
			});
		} else if (typeof (attribute) === 'object') {
			for (const k in attribute) {
				elem[k] = attribute[k];
			}
		}
	});

	return elem;
};

/**
* Delaying different actions, used for animation steps effects
* @param {number} milliseconds duration of delay
*/
export const sleep = (milliseconds: number) => {
	return new Promise(resolve => setTimeout(resolve, milliseconds))
};


export const formattedDate = (milliseconds: number | string, locale: string = 'en-US'): string => {
	if (typeof milliseconds === 'undefined') {
	  return '';
	}
  
	const date = new Date(milliseconds);
  
	return date.toLocaleDateString(locale, {
		weekday: 'long', 
		year: 'numeric', 
		month: 'long', 
		day: 'numeric',
		timeZone: 'UTC'
	});
};

export const cultureFormattedDate = (milliseconds: number | string): string => {
	if (typeof milliseconds === 'undefined') {
		return '';
	}
	
	const locale = document.querySelector('body')?.getAttribute('data-culture') ?? 'en-US';
	const date = new Date(milliseconds);

	if(locale.toLowerCase() === 'en' || locale.toLowerCase() === 'en-us') {
		return date.toLocaleDateString(locale, {
			year: 'numeric', 
			month: 'numeric', 
			day: 'numeric',
			timeZone: 'UTC'
		});
	}

	return date.toLocaleDateString(locale, {
		weekday: 'long', 
		year: 'numeric', 
		month: 'long', 
		day: 'numeric',
		timeZone: 'UTC'
	});
};

export const dateWithSlash = (date: Date): string => {
	return (
		('0' + (date.getMonth() + 1)).slice(-2) +
		'/' +
		('0' + date.getDate()).slice(-2) +
		'/' +
	  	date.getFullYear()
	);
};

export const dateWithTimeZone = (year: number, month: number, day: number, hour: number, minute:number, second: number) => {
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	let date = new Date(Date.UTC(year, month, day, hour, minute, second));
  
	let utcDate = new Date(date.toLocaleString('en-US', { timeZone: "UTC" }));
	let tzDate = new Date(date.toLocaleString('en-US', { timeZone: timeZone }));
	let offset = utcDate.getTime() - tzDate.getTime();
  
	date.setTime( date.getTime() + offset );
  
	return date;
};

export const dateWithDashes = (date: Date): string => {
	return (
	  date.getFullYear() +
	  '-' +
	  ('0' + (date.getMonth() + 1)).slice(-2) +
	  '-' +
	  ('0' + date.getDate()).slice(-2)
	);
  };

export const expiryDate = (date: string): string => {
	if (date.startsWith("0001-")) return "";

	let d = new Date(date);
	return t.label.expire + " " + cultureFormattedDate(d.getTime());
}

export const repeatFunction = (numberOfTime: number, fn: (i: number) => any) => {
	const arr = [];
	while (numberOfTime > arr.length) {
	  arr.push(fn(arr.length));
	}
	return arr;
}

export const getAllDaysInMonth = (year: number, month: number) => {
	const date = new Date(year, month, 1);
  
	const dates = [];
  
	while (date.getMonth() === month) {
	  dates.push(dateWithDashes(new Date(date)));
	  date.setDate(date.getDate() + 1);
	}
  
	return dates;
}

export const dateIsExpired = (str: string) => {
	const dateFromStr = new Date(str);

	const now = new Date();
	if (dateFromStr.setHours(0, 0, 0, 0) <= now.setHours(0, 0, 0, 0)) {
	  return true;
	}
  
	return false;
};

export const passIsOngoing = (str: string) => {
	const dateFromStr = new Date(str);

	const now = new Date();
	const twoYearsFromNow = now.setFullYear(now.getFullYear() + 2);
	if (dateFromStr.setHours(0, 0, 0, 0) >= twoYearsFromNow) {
		return true;
	}

	return false;
};


export const replaceTerm = (value: string, hash: any) => {
	for (const key in hash) {
	  value = value.replace(new RegExp('\\{{' + key + '\\}}', 'gm'), hash[key]); 
	}
  
	return value;
};

export const windowRef = window as any;
export const t = windowRef.prebookTranslations;