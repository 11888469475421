import { makeElement } from "../utils/utils";

import FormValidation from '../utils/form-validation';
// const FormValidation = require('../utils/form-validation.js');

/**
 * Step Form object
 * @param {HTMLElement} el
 * @param {number} startAtStep which step you want the form to start at, 0 is default
 */
 export default class Stepper {
	private el: HTMLElement;
	private options: any;
	private steps: HTMLElement[];
	private nextButton: HTMLButtonElement;
	private currentStep: number;
	private formControls: HTMLElement[];
    formValidation: FormValidation;
	
    constructor(el: HTMLElement, options: any) {
        
        this.el = el;
		this.options = options;
        this.steps = Array.from(this.el.querySelectorAll('[data-step]'));
        this.formControls = Array.from(this.el.querySelectorAll('.input-field'));
        this.nextButton = this.el.querySelector('.next-button') || makeElement("button", "next-button");
        this.currentStep = this.options.startAtStep || 0;

        this.init();
    }

    init() {
        this.showStep(this.currentStep);
		this.renderNavigationItems(this.options.navigationSteps, this.currentStep);
        this.addEvents();
    }

    showStep(step = 0) {
        this.steps[step].classList.add('is-active');
        this.nextButton.innerHTML = `<span>${this.nextButton.dataset[step === this.steps.length - 1 ? 'finalStepText' : 'stepText'] || ''}</span>`;
        this.updateNavigationItems(step);

        if(this.options.handleStepChange) {
			this.options.handleStepChange(step);
		}

        try {
            this.formValidation = new FormValidation(this.steps[this.currentStep], this.el.querySelector('.bottom-panel-wrapper'), { showGlobalErrorFlag: true });
        } catch(e: any) {
            console.error(e.message);
        }
    }

    showGlobalError(message: string) {
        this.formValidation?.showGlobalError(message);
    }

    navigate(step: number) {
		if (step === this.currentStep) {
            return;
        }

        if (step > this.currentStep && !this.formValidation.onSubmit()) {
            return;
        }

		if (step >= this.steps.length) {
            this.onSubmitCallback();
            return;
        }

        this.formValidation?.hideGlobalError(false);
        this.steps[this.currentStep].classList.remove('is-active');
        this.currentStep = step;
        this.showStep(this.currentStep);
    }

	async onSubmitCallback() {
		if(this.options.onSubmit) {
			await this.options.onSubmit();
		}
	}

	getNextStep() {
		this.navigate(this.currentStep + 1);
	}

	onStepChange(step: number) {
		if (step < this.currentStep) {
			this.navigate(step);
		}
	}

	renderNavigationItems(steps: any[], currentStep: number) {
		if(steps.length === 0) {
			return;
		}
	
		const container = document.querySelector('.prebook-navigation');
	
		if(!container) {
			return;
		}
	
		const markup = `
			<ul>
				${steps.map((step, i) => `
					<li>
						<button type="button" class="prebook-navigation-item ${currentStep === i ? 'active' : ''}" data-index="${i}">
							${step.icon}
							<span>${step.title}</span>
						</button>
					</li>
				`).join('')} 
			</ul>
		`;
		container.innerHTML = markup;
		Array.from(document.querySelectorAll('.prebook-navigation-item')).map((button, i) => button.addEventListener('click', this.onStepChange.bind(this, i), false));
	}

	updateNavigationItems(step: number) {
        Array.from(document.querySelectorAll('.prebook-navigation-item')).map((item, index) => {
            item.classList.remove('active');
            if(index === step) {
                item.classList.add('active');
            }
        });
    }

    clearErrors(e: Event) {
        (e.target as any).closest('.input-group').classList.remove('has-error');
        this.nextButton.disabled = false;
    }

    addEvents() {
        for (let formControl of this.formControls) {
            formControl.addEventListener('keyup', this.clearErrors.bind(this));
            formControl.addEventListener('change', this.clearErrors.bind(this));
        }

        this.nextButton.addEventListener('click', this.getNextStep.bind(this));
		const form = this.el.querySelector('form');
        
        form && form.addEventListener('submit', this.onSubmitCallback.bind(this));
    }
}