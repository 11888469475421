import { sleep } from "./utils";

export const startButtonLoadingState = (button: HTMLButtonElement, callback?: () => void) => {
	if(!button) {
		return;
	}

	const text = button.querySelector('span');

	if(!text) {
		return;
	}

	text.classList.add('is-hidden');
	button.setAttribute('disabled', '');

	getLoadingDots(button, () => {
		callback && callback();
	}); 

	// const onTransitionEnd = () => {
	// 	text.removeEventListener('transitionend', onTransitionEnd);
	// 	getLoadingDots(button, () => {
	// 		callback();
	// 	});       
	// }

	// text.addEventListener('transitionend', onTransitionEnd);

}

const getLoadingDots = (button: HTMLButtonElement, callback: () => void) => {
	if(!button) {
		return;
	}

	const alreadyDots = button.querySelector('.load-dot-container');

	if(alreadyDots) {
		return;
	}

	const markup = `
		<div class="load-dot-container">
			<div class="load-dot first"></div>
			<div class="load-dot second"></div>
			<div class="load-dot third"></div>
		</div>
	`;

	button.innerHTML += markup;

	callback();

	
}

export const endButtonLoadingState = async (button: HTMLButtonElement) => {
	await sleep(400);
	button.removeAttribute('disabled');

	const dot = button.querySelector('.load-dot-container');

	if(dot) {
		dot.remove();
	}

	const text = button.querySelector('span');

	if(text) {
		text.classList.remove('is-hidden');
	}
}