import { Datepicker } from 'vanillajs-datepicker';
import { convertReservationToBooking, createReservation, getAttractionAvailability, getListOfPassesForAttraction } from '../api/umbraco-api';
import Notification from '../components/notification';
import Stepper from '../components/stepper';
import { bookingSuccessful } from '../constants/dialogs';
import DialogViewBase, { ButtonVariation, IDialogOptions } from '../core/dialog-view-base';
import { PubSub } from '../core/pubsub';
import { arrivalTimeData, editNavigationSteps, ticketSvg } from '../data/data';
import { deDE, enAU, enGB, esES, itIT, ptBR, ptPT } from '../i18n/locales';
import { IBooking, clearSummaryTicket, generateSummaryTicket, getArrivalTime, renderArrivalTime, renderPassListData, renderUnderThreeTicket } from '../templates/list-items';
import { IAvailability, IGetAttractionAvailability, IPass, isFetchErrorResponse } from '../types/main';
import { endButtonLoadingState, startButtonLoadingState } from '../utils/animations';
import { dateWithDashes, dateWithTimeZone, formattedDate, getAllDaysInMonth, makeElement, replaceTerm, sleep, t } from '../utils/utils';

interface IExtendedDialogOptions extends IDialogOptions {
	onConfirm: () => void;
	onReject: () => void;
	primaryButtonVariation?: ButtonVariation;
	secondaryButtonVariation?: ButtonVariation;
	startAtStep: number;
}

const pubSub = PubSub<events>();

export default class EditBookingDialog extends DialogViewBase {
	booking: IBooking;
	stepper: Stepper | null;
	passData: IPass[] | null;
	data: IBooking;
	datePicker: Datepicker | null;
	currentSelectedDate: Date;
	attractionAvailability: IAvailability[];
	timer: any | null;
	submitButton: HTMLButtonElement;
	constructor(booking: IBooking, options: IExtendedDialogOptions) {
		super(options);

		this.data = booking;
		this.data.passtypes = this.data.nicknames.filter(x => !x.isFreeTicket).map(pass => pass.passTierId);
		this.data.barcodes = this.data.nicknames.filter(x => !x.isFreeTicket).map(pass => pass.barCode);
		this.attractionAvailability = [];
		this.timer = null;

		const numbers: number[] = this.data.startDate.split('-').map((number, index) => {
			if (index === 1) return parseInt(number) - 1; //The month is zero index, so we need to subtract 1
			return parseInt(number);
		});

		this.currentSelectedDate = dateWithTimeZone(numbers[0], numbers[1], numbers[2], 0 ,0, 0);

		this.onSubmit = this.onSubmit.bind(this);
		this.changeDate = this.changeDate.bind(this);
		this.changeMonth = this.changeMonth.bind(this);
		this.setArrivalTime = this.setArrivalTime.bind(this);
		this.handleStepChange = this.handleStepChange.bind(this);
		this.underThreeTicketHandler = this.underThreeTicketHandler.bind(this);
		pubSub.subscribe("UnderThreeTicketsChanged", (tickets) => {
			const underThreeTicketButton = document.querySelector('.add-under-three-ticket-button') as HTMLButtonElement;
			if (tickets.number >= 4) {
				underThreeTicketButton.disabled = true;
			} else {
				underThreeTicketButton.disabled = false;
			}
		});
	}

	async fetchPassData() {
		const wrapper = this.el.querySelector('.pass-selection');

		if(!wrapper) {
			return;
		}

		startButtonLoadingState(this.submitButton);
		await sleep(400);

		try {
			const response: IPass[] = await getListOfPassesForAttraction(this.data.attraction.merlinAttractionId);

			if (isFetchErrorResponse(response)) {
				throw response;
			}

			this.passData = response;
			endButtonLoadingState(this.submitButton);
		} catch (error) {
			this.passData = [];
			Notification.add(error.errorMessage || error.detail);
			endButtonLoadingState(this.submitButton);
		}

		const render = () => {
			wrapper.innerHTML = renderPassListData(this.passData, this.data.barcodes);

			// Register event listeners
			const passes = Array.from(this.el.querySelectorAll('[data-pass]'));
			for (let pass of passes) {
				pass.addEventListener('change', () => {
					const checkedPasses = Array.from(this.el.querySelectorAll('[data-pass]:checked'));

					this.data.nicknames = checkedPasses.map((input: any) => ({
						nickname: input.value,
						passTierId: input.getAttribute('data-pass'),
						expiryDate: input.getAttribute('data-expiry-date'),
						barCode: input.getAttribute('data-barcode'),
						isFreeTicket: false,
					}));
					this.data.passtypes = checkedPasses.map((input: any) => input.getAttribute('data-pass'));
					this.data.barcodes = checkedPasses.map((input: any) => input.getAttribute('data-barcode'));
				});
			}
		}

		if(this.datePicker) {
			this.datePicker.destroy();
			this.datePicker = null;
		}

		render();

		if(this.data.freeTickets && this.data.freeTickets > 0) {
			this.underThreeTicketHandler(this.data.freeTickets);
		}
	}

	async changeDate(e: any) {
		const date = e.detail.viewDate;

		if(!date) {
			return;
		}

		const dateObject = new Date(date);
		const d = dateWithTimeZone(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate(), 0 ,0, 0);
		this.data.startDate = dateWithDashes(d);
		this.data.startTime = null;

		this.displayArrivalTime(dateObject);
		this.el.querySelector('.prebook-arrival-time')?.classList.remove('has-message');
	}

	async changeMonth(e: any) {
		this.data.startDate = '';
		this.currentSelectedDate = new Date(e.detail.viewDate);
		this.initDatePicker(null);

		if (this.timer) {
			clearTimeout(this.timer);
		}
		const myTimer = this.timer = window.setTimeout(() => this.callAsyncDatePickerForMonth(dateWithDashes(this.currentSelectedDate), myTimer), 600);
	}

	callAsyncDatePickerForMonth(date: string, timer: number) {
		if (timer !== this.timer) {
			// the request is outdated
			return;
		}

		this.initDatePickerForMonth(date);
	}

	async initDatePickerForMonth(date: string) {
		const passtypes = this.data.passtypes ? this.data.passtypes.join(',') : '';
		let datesDisabled: null | string[] = null;

		startButtonLoadingState(this.submitButton);

		try {
			const response: IGetAttractionAvailability = await getAttractionAvailability(passtypes, this.data.attraction.accessoAttractionId, date);

			if (isFetchErrorResponse(response)) {
				throw response;
			}

			this.attractionAvailability = response.availabilities;
			const passRestrictionWrapper: HTMLElement | null = this.el.querySelector('.pass-restriction-wrapper');

			if(passRestrictionWrapper && response.restrictions.length > 0) {
				passRestrictionWrapper.innerHTML = `<div class="pass-restriction">${t.feedback.restrictedDates} <strong>${response.restrictions}</strong></div>`;
			}

			datesDisabled =
			this.attractionAvailability
				.filter(date => {
					return date.isBlockedOut
				})
				.map(date => date.calendarDate);

			endButtonLoadingState(this.submitButton);
		} catch (error) {
			Notification.add(error.errorMessage || error.detail);
			endButtonLoadingState(this.submitButton);
		}


		this.initDatePicker(datesDisabled, date);
	}

	async initDatePicker(datesDisabled: string[] | null, setDate?: string) {
		const amount = this.data.barcodes.length + this.data.freeTickets;

		getArrivalTime(this.el, [], this.setArrivalTime, amount, this.data.startTime);
		const dateInputElement: HTMLElement | null = this.el.querySelector('.date-input-wrapper');


		if(!datesDisabled) {
			datesDisabled = getAllDaysInMonth(this.currentSelectedDate.getFullYear(), this.currentSelectedDate.getMonth());
		}

		const convertedDisabledDates = datesDisabled.map((dateString) => {
			const numArr = dateString.split('-');
			return numArr[1] + " " + numArr[2] + " " + numArr[0];
		});

		Object.assign(Datepicker.locales, deDE, enAU, enGB, esES, itIT, ptBR, ptPT);

		const currentCulture = document.querySelector('body')?.getAttribute('data-culture') ?? 'en';
		let lang = 'en'

		if (Datepicker.locales.hasOwnProperty(currentCulture)) {
			lang = currentCulture
		}

		const options = {
			language: lang,
			datesDisabled: convertedDisabledDates,
			minDate: new Date(),
			defaultViewDate: this.currentSelectedDate,
		};

		if(dateInputElement) {
			if(this.datePicker) {
				dateInputElement.removeEventListener('changeDate', this.changeDate);
				dateInputElement.removeEventListener('changeMonth', this.changeMonth);
				this.datePicker.destroy();
				dateInputElement.innerHTML = '';
			}

			this.datePicker = new Datepicker(dateInputElement, options);

			if(setDate) {
				const dateObject = formattedDate(setDate);
				this.datePicker.setDate(dateObject);
				this.data.startDate = dateObject;
				this.displayArrivalTime(new Date(setDate));
			}

			dateInputElement.addEventListener('changeDate', this.changeDate);
			dateInputElement.addEventListener('changeMonth', this.changeMonth);
		}

		this.registerEventListenersForDisabledDates();
	}

	displayArrivalTime(forDate: Date) {
		if(!this.attractionAvailability) {
			return;
		}

		const datesAvailable =
			this.attractionAvailability
				.filter(date => !date.isBlockedOut);

		const selectedDate = datesAvailable.find(date => date.calendarDate === dateWithDashes(forDate));

		if(!selectedDate) {
			return;
		}

		if(!selectedDate.timeSlots) {
			this.data.startTime = null;
		}

		const amount = this.data.barcodes.length + this.data.freeTickets;

		getArrivalTime(this.el, selectedDate.timeSlots || [], this.setArrivalTime, amount, this.data.startTime);
	}

	setArrivalTime(e: Event) {
		const el = e.target as HTMLInputElement;
		this.data.startTime = el.getAttribute('data-start-time');
	}

	registerEventListenersForDisabledDates() {
		Array.from(this.el.querySelectorAll('.datepicker-cell.disabled')).forEach((day: Element) => {
			const callback = (day: Element) => {
				let message = '', date = '';
				const selectedDateMs = day.getAttribute('data-date');

				if(selectedDateMs) {
					date = dateWithDashes(new Date(parseInt(selectedDateMs)));
				}

				const attraction = this.attractionAvailability.find(attraction => attraction.calendarDate === date);

				if(attraction){
					if(attraction.cardType.length > 0) {
						message = replaceTerm(t.error.unavailableDate, {
							pass: attraction.cardType
						});
					} else {
						message = attraction.blockOutReason;
					}
				}

				this.stepper?.showGlobalError(message);
			}

			day.addEventListener('click', () => callback(day));
		});
	}

	async createReservation() {
		startButtonLoadingState(this.submitButton);

		try {
			clearSummaryTicket(this.el);
			await sleep(500);

			console.log('this.data.startDate: ', this.data.startDate);

			const numbers: number[] = this.data.startDate.split('-').map((number, index) => {
				if (index === 1) return parseInt(number) - 1; //The month is zero index, so we need to subtract 1
				return parseInt(number);
			});

			const newArrivalDateTimeZoneAdjusted = dateWithTimeZone(numbers[0], numbers[1], numbers[2], 0 ,0, 0);
			const newArrivalDate = dateWithDashes(newArrivalDateTimeZoneAdjusted);

			console.log('newArrivalDateTimeZoneAdjusted: ', newArrivalDateTimeZoneAdjusted);
			console.log('newArrivalDate: ', newArrivalDate);

			const response: string | any = await createReservation({
				attractionAccessoId: this.data.attraction.accessoAttractionId,
				startDate: this.data.startDate,
				startTime: this.data.startTime || '',
				quantity: this.data.barcodes.length + this.data.freeTickets,
				freeTickets: this.data.freeTickets,
			});

			if (response.errorMessage) {
				throw response;
			}

			this.data.reservationId = response;

			generateSummaryTicket('edit', this.data, this.el, this.stepper);
			endButtonLoadingState(this.submitButton);

		} catch (error) {
			Notification.add(error.errorMessage || error.detail);
			endButtonLoadingState(this.submitButton);
		}
	}

	async onSubmit() {
		startButtonLoadingState(this.submitButton);

		try {
			await sleep(1000);

			if(!this.data.startDate || this.data.startDate === '') {
				throw 'Date must be selected';
			}

			console.log('this.data.startDate: ', this.data.startDate);

			const numbers: number[] = this.data.startDate.split('-').map((number, index) => {
				if (index === 1) return parseInt(number) - 1; //The month is zero index, so we need to subtract 1
				return parseInt(number);
			});

			const newArrivalDateTimeZoneAdjusted = dateWithTimeZone(numbers[0], numbers[1], numbers[2], 0 ,0, 0);
			const newArrivalDate = dateWithDashes(newArrivalDateTimeZoneAdjusted);

			console.log('newArrivalDateTimeZoneAdjusted: ', newArrivalDateTimeZoneAdjusted);
			console.log('newArrivalDate: ', newArrivalDate);

			const response: any = await convertReservationToBooking({
				attractionAccessoId: this.data.attraction.accessoAttractionId,
				startDate: this.data.startDate,
				startTime: this.data.startTime || '',
				reservationId: this.data.reservationId || '',
				bookingId: this.data.bookingId || '',
				barcodes: this.data.barcodes,
				freeTickets: this.data.freeTickets,
			});

			if (response.errorMessage) {
				throw response;
			}

			if(response.orderid) {
				this.data.bookingId = response.orderid;
			}

			bookingSuccessful(this.data);
		} catch (error) {
			Notification.add(error.errorMessage || error.detail);
			endButtonLoadingState(this.submitButton);
		}
	}

	stringToHTML(str: string) {
		var parser = new DOMParser();
		var doc = parser.parseFromString(str, 'text/html');
		return doc.body;
	};

	underThreeTicketHandler(number: number = 1) {
		if(!this.passData) {
			return;
		}

		const wrapper = this.el.querySelector('.pass-selection__list');

		if(!wrapper) {
			return;
		}

		if(this.el.querySelectorAll('[data-under-three]').length >= 4) {
			return;
		}

		// This re-renders all the normal tickets, which unselects them when adding an under 3 pass
		// wrapper.innerHTML += renderUnderThreeTicket();

		const fragment = document.createDocumentFragment();

		for (let i = 0; i < number; i++) {
			const under3Ticket = renderUnderThreeTicket() as string;
			const el = this.stringToHTML(under3Ticket) as HTMLBodyElement;
			const li = el.querySelector('li') as HTMLLIElement;

			fragment.appendChild(li);
		}


		wrapper.appendChild(fragment);

		// Register event listeners
		const tickets = Array.from(this.el.querySelectorAll('[data-under-three]'));
		this.data.freeTickets = tickets.length;

		for (let ticket of tickets) {
			ticket.addEventListener('change', (e: Event) => {
				const el = e.target as HTMLInputElement;

				if(!el.checked) {
					el.parentElement && el.parentElement.remove();

					const ticketsAfterRemoval = Array.from(this.el.querySelectorAll('[data-under-three]'));
					this.data.freeTickets = ticketsAfterRemoval.length;
					pubSub.publish("UnderThreeTicketsChanged", { number: this.data.freeTickets });
				}
			});
		}
		pubSub.publish("UnderThreeTicketsChanged", { number: this.data.freeTickets });
	}

	registerEventListeners() {
		this.submitButton = this.el.querySelector('.next-button') || makeElement("button", "next-button");

		const underThreeTicketButton = document.querySelector('.add-under-three-ticket-button') as HTMLButtonElement;

		if (underThreeTicketButton) {
			if (this.data.attraction.allowFreeTickets) {
				underThreeTicketButton.addEventListener('click', () => this.underThreeTicketHandler(1));
			} else {
				underThreeTicketButton.hidden = true;
			}
		}

		const stepperElement: HTMLElement | null = this.el.querySelector('[data-multi-stepper]');
		if(stepperElement) {
			const options = {
				navigationSteps: editNavigationSteps,
				startAtStep: this.options.startAtStep || 0,
				onSubmit: this.onSubmit,
				handleStepChange: this.handleStepChange,
			}
			this.stepper = new Stepper(stepperElement, options);
		}
	}

	handleStepChange(step: number) {
		switch(step) {
			case 0:
				this.fetchPassData();
				break;
			case 1:
				if(!this.datePicker) {
					this.initDatePicker(null).then(() => {
						this.initDatePickerForMonth(dateWithDashes(this.currentSelectedDate));
					});
				}
				break;
			case 2:
				this.createReservation();
				break;
		}
	}

	render() {
		const wrapper = this.el.querySelector('.dialog-content-wrapper');

		if(!wrapper) {
			return;
		}

		const amount = this.data.barcodes.length + this.data.freeTickets;

		wrapper.innerHTML = `
			<div class="booking-dialog-wrapper multi-step-form-wrapper" data-multi-stepper>
				<div class="content">
					<nav class="prebook-navigation"></nav>
					<div class="">
						<form class="multi-step-form" method="get" novalidate>
							<fieldset class="step" data-step>
								<div class="prebook">
									<div class="pass-selection">${renderPassListData(this.passData)}</div>
									<button class="add-under-three-ticket-button" type="button">${ticketSvg} ${t.button.addUnderThreeTicket}</button>
								</div>
							</fieldset>

							<fieldset class="step" data-step>
								<div class="prebook">
								<div class="date-input-wrapper input-validation" data-validate="date" data-error="${t.error.date}"></div>
								<div class="pass-restriction-wrapper"></div>
									<div class="prebook-arrival-time">
										<div class="arrival-time">
											<div class="arrival-time__title">Whats your arrival time?</div>
											<div class="arrival-time__selection">
												${renderArrivalTime(arrivalTimeData, amount)}
											</div>
										</div>
									</div>
								</div>
							</fieldset>

							<fieldset class="step" data-step>
								<div class="prebook">
									<div class="ticket-confirm"></div>
								</div>
							</fieldset>
						</form>
					</div>

				</div>
				<div class="bottom-panel-wrapper">
					<div class="button-wrapper">
						<button class="button button--primary next-button" data-step-text="${t.button.continue}" data-final-step-text="${t.button.updateBooking}"><span>${t.button.continue}</span></button>
					</div>
				</div>
			</div>
		`;

		this.registerEventListeners();
		this.show();
	}
}