const defaultOptions = {
	accordionFoldContainerSelector: '.accordion-fold-container',
	accordionFoldSelector: '.accordion-fold',
	accordionActiveClass: 'active',
	accordionLink: 'accordion-item-link',
    accordionHeader: '.accordion-header'
};

export interface DefaultOptions {
	accordionFoldContainerSelector: string;
	accordionFoldSelector: string;
	accordionActiveClass: string;
	accordionLink: string;
    accordionHeader: string;
}

export default class Accordion {
    el: HTMLElement;
	options: DefaultOptions;
	constructor(el: HTMLElement, options?: DefaultOptions) {
		this.el = el;
		this.options = options ? options : defaultOptions;
		const fold = this.el.querySelector(this.options.accordionFoldSelector);
        const header = this.el.querySelector(this.options.accordionHeader);
		if (!fold || !header) { return; }

		header.addEventListener('click', this.onAccordionToggle.bind(this));

		if (this.el.classList.contains('active')) {
			this.toggleAccordion(true);
		}
	}

	onAccordionToggle() {
		if (this.el.classList.contains(this.options.accordionActiveClass)) {
			this.toggleAccordion(false);
		} else {
			this.toggleAccordion(true);
		}
	}

	toggleAccordion(open: boolean) {
		let addedPaddingOnAccordionHeight = 0;

		if (open) {
			const accordionHeight = Array.from(this.el.querySelectorAll(this.options.accordionFoldSelector)).map(container => parseInt((container as any).offsetHeight)).reduce((total, amount) => total + amount);
			addedPaddingOnAccordionHeight = accordionHeight + 50;
			this.el.classList.add(this.options.accordionActiveClass);
		} else {
			this.el.classList.remove(this.options.accordionActiveClass);
		}

		const isActive = this.el.classList.contains(this.options.accordionActiveClass);
		(this.el.querySelector(this.options.accordionFoldContainerSelector) as any).style.maxHeight = `${isActive ? addedPaddingOnAccordionHeight : 0}px`;
	}
}
