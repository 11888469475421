import { attractionAvailabilityRoute, bookingRoutes, listsRoutes, passesForAttractionRoute, passRoutes } from "../constants/api-routes";
import { ITicket } from "../templates/list-items";
import { IAttraction, IGetAttractionAvailability, IPass } from "../types/main";
import fetchJson from "../utils/fetchJson";

interface IConvertReservationToBookingBody {
  attractionAccessoId: string;
  startDate: string;
  startTime: string;
  reservationId: string;
  bookingId: string;
  barcodes: string[];
  freeTickets: number;
}

interface ICreateReservationBody {
  attractionAccessoId: string;
  startDate: string;
  startTime: string;
  quantity: number;
  freeTickets: number;
}

export async function getAttractionAvailability(passtypes: string, attractionid: string, fromDate: string) {
  const data: IGetAttractionAvailability =
    await fetchJson(attractionAvailabilityRoute(passtypes, attractionid, fromDate));

  return data;
};

export async function cancelBooking(bookingId: string) {
  const data: any =
    await fetchJson(`${bookingRoutes.cancelBooking}?bookingId=${bookingId}`, {
      method: 'DELETE',
    });

  return data;
};

export async function addPass(body: any) {
  const data: any =
    await fetchJson(passRoutes.add, {
      method: 'POST',
      body: JSON.stringify(body),
    });

  return data;
};

export async function deletePass(barCode: string) {
  const data: any =
    await fetchJson(`${passRoutes.remove}/${barCode}`, {
      method: 'DELETE',
    });

  return data;
};

export async function createReservation(body: ICreateReservationBody) {
  const data: any =
    await fetchJson(bookingRoutes.createReservation, {
      method: 'POST',
      body: JSON.stringify(body),
    });

  return data;
};

export async function convertReservationToBooking(body: IConvertReservationToBookingBody) {
  const data: any =
    await fetchJson(bookingRoutes.convertReservationToBooking, {
      method: 'POST',
      body: JSON.stringify(body),
    });

  return data;
};

export async function getListOfPassesForAttraction(attractionid: string) {
  const data: IPass[] =
    await fetchJson(passesForAttractionRoute(attractionid));

  return data;
};

export async function getListOfAttractions() {
  const data: IAttraction[] =
    await fetchJson(listsRoutes.attractions);

  return data;
};

export async function getTickets(bookingId: string) {
  const data: ITicket[] =
    await fetchJson(`${bookingRoutes.getTickets}?bookingId=${bookingId}`);

  return data;
};