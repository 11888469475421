import { IFetchError, isFetchErrorResponse } from '../types/main';

export default async function fetcher<TResponse = any>( // remove the default "any" when enough uses has been typed correctly
  url: RequestInfo,
  requestInfo?: RequestInit,
): Promise<TResponse | any> {

  const defaultHeaders = {
    'Content-Type': 'application/json',
  };

  const modifiedRequestInfo = Object.assign(requestInfo || {}, {
    headers: Object.assign(defaultHeaders, requestInfo ? requestInfo.headers : {}),
  });
  try {

    let response = await fetch(url, modifiedRequestInfo);
    const contentType = response.headers.get('content-type') || '';

    // Any errors that are not critical network errors throw our own error response
    if (!response.ok) {
      const error = new Error('An error occurred while fetching the data.');
      let errorResponseJson = response;


      if (contentType.includes('application/json') || contentType.includes('application/problem+json')) {
        errorResponseJson = await response.json();
      }

      if (contentType.includes('text/html')) {
        return { errorMessage: 'There was an error' };
      }

      if (!isFetchErrorResponse(errorResponseJson)) {
        throw errorResponseJson;
      }

      const errorObject: IFetchError = {
        errors: errorResponseJson ? errorResponseJson.errors : [],
        errorCode: response.status || errorResponseJson.errorCode,
        errorMessage:
          errorResponseJson.detail ||
          errorResponseJson.errorMessage ||
          error.message ||
          'There was an error',
      };

      return errorObject;
    }

    if (contentType.includes('application/pdf')) {
      return await response.blob();
    }

    if (contentType.includes('application/json')) {
      return await response.json();
    }

    return response;
  } catch (error) {
    if (!error.data) {
      error.data = { message: error.message };
    }
    throw error;
  }
}
