import { editBooking } from '../constants/dialogs';
import DialogViewBase, { ButtonVariation, IDialogOptions } from '../core/dialog-view-base';
import { IBooking, renderPassFullItem, renderBookingSummary } from '../templates/list-items';
import { IPassFullModel } from '../types/main';
import { endButtonLoadingState, startButtonLoadingState } from '../utils/animations';

export interface IExtendedDialogOptions extends IDialogOptions {
	booking?: IBooking;
	pass?: IPassFullModel;
	onConfirm: () => void;
	onReject: () => void;
	primaryButtonVariation?: ButtonVariation;
	secondaryButtonVariation?: ButtonVariation;
	primaryButtonText?: string;
	secondaryButtonText?: string;
	isEditMode?: boolean;
	confirmHandlerWithoutClosingDialog?: boolean;
}

export default class DialogView extends DialogViewBase {
	primaryButton: HTMLButtonElement | null;
	secondaryButton: HTMLButtonElement | null;
	addEventListeners?: () => void;
	constructor(options: IExtendedDialogOptions) {
		super(options);
		this.handleConfirm = this.handleConfirm.bind(this);
		this.handleReject = this.handleReject.bind(this);
	}

	async handleConfirm() {
		this.primaryButton && startButtonLoadingState(this.primaryButton);

		if(this.options.onConfirm) {
			await this.options.onConfirm();
		}

		this.primaryButton && endButtonLoadingState(this.primaryButton);

		if(!this.options.confirmHandlerWithoutClosingDialog) {
			this.remove();
		}	
	}

	handleReject() {
		this.remove();
	}

	registerEventListeners() {
		this.primaryButton = this.el.querySelector('[data-primary-button]');
		this.secondaryButton = this.el.querySelector('[data-secondary-button]');
		
		if(this.primaryButton) {
			this.primaryButton.addEventListener('click', this.handleConfirm);
		}
		if(this.secondaryButton) {
			this.secondaryButton.addEventListener('click', this.handleReject);
		}

		if(this.options.registerEventListeners) {
			this.options.registerEventListeners();
		}

		if(this.options.isEditMode) {
			this.handleEditBookingEventListeners();
		}
	}

	handleEditBookingEventListeners() {
		const summaryGuestsEditButton = this.el.querySelector('[data-summary-guests]');
		const summaryDateEditButton = this.el.querySelector('[data-summary-date]');

		if(summaryGuestsEditButton) {
			summaryGuestsEditButton.addEventListener('click', () => editBooking(this.options.booking, 0));
		}
		if(summaryDateEditButton) {
			summaryDateEditButton.addEventListener('click', () => editBooking(this.options.booking, 1));
		}
	}

	render() {
		const wrapper = this.el.querySelector('.dialog-content-wrapper');

		if(!wrapper) {
			return;
		}
		
		wrapper.innerHTML = `
			<div class="add-pass-dialog-wrapper">
				<div class="content">
					<h3>${this.options.title}</h3>
					${this.options.message ? `<p>${this.options.message}</p>` : ''}
				</div>
				<div>
				${this.options.booking ? renderBookingSummary(this.options.booking, this.options.isEditMode) : ''}
				${this.options.pass ? renderPassFullItem(this.options.pass) : ''}
				</div>
				<div class="bottom-panel-wrapper">
					${this.options.primaryButtonText ? `<button class="button ${this.computeButtonVariation(this.options.primaryButtonVariation || 'primary')}" data-primary-button><span>${this.options.primaryButtonText}</span></button>` : ''}
					${this.options.secondaryButtonText ? `<button class="button ${this.computeButtonVariation(this.options.secondaryButtonVariation || 'secondary')}" data-secondary-button><span>${this.options.secondaryButtonText}</span></button>` : ''}
				</div>
			</div>
		`;

		this.registerEventListeners();
		this.show();
	}
}
