export interface IFetchError {
	errors: any[];
	errorCode: number;
	errorMessage: string;
	detail?: string;
  }
  
  export function isFetchErrorResponse(
	response: IFetchError | any,
  ): response is IFetchError {
	return (response as IFetchError).errorCode !== undefined || (response as IFetchError).errorMessage !== undefined;
  }
  
  export interface IAttraction {
	attractionName: string;
	description?: string;
	merlinAttractionId: string;
	accessoAttractionId: string;
	imageUrl?: string;
	allowFreeTickets: boolean;
  }
  
  export interface IPass {
	nickname: string;
	imageUrl?: string;
	expiryDate: string;
	passTier: string;
	passTierId: string;
	barCode: string;
  }

  export interface IPassFullModel {
	id: string;
	nickname: string;
	barCode: string;
	expiryDate: string;
	isExpired: boolean;
	passTypeId: string;
	passTier: string;
	imageUrl?: string;
	description?: string;
  }
  
  export interface ITimeslot {
	  startTime: string;
	  capacity: string;
	  available: string;
	  endTime: string;
  }
  
  export interface IAvailability {
	  calendarDate: string;
	  isBlockedOut: boolean;
	  blockOutReason: string;
	  cardTypeid: string;
	  cardType: string;
	  timeSlots: ITimeslot[] | null;
  }
  
  export interface IGetAttractionAvailability {
	  restrictions: string;
	  availabilities: IAvailability[];
  }