import { makeElement, sleep } from './utils';

const Default = {
	interval : 3000
};


/**
 * This method handles all validation functions inside our form
 * @param {HTMLElement} el container where you want validation, for example in our Stepper Form, this would be the current step
 * @param {HTMLElement} context main wrapper, that contains the entire form
 * @param {Object} options { showError: boolean; showGlobalErrorFlag: boolean; } local/global validation error messages
 */
 export default class FormValidation {
	el: HTMLElement;
	context: any;
	options: any;
	errorTemplate: HTMLElement;
	_interval: any;
	_isPaused: boolean;
	constructor(el: HTMLElement, context: any, options: any) {
		this.el = el;
		this.context = context;
		this.options = {
			showError: true,
			showGlobalErrorFlag: false
		};
		this.options = {...this.options, ...options};
		this._isPaused = false;
		this._interval = null;
		const temp = document.querySelector("#error-template") as any;
		this.errorTemplate = temp && document.importNode(temp.content, true).querySelector(".error-template");
	}
	onInputChange(e: Event) {
		this.validateInput(e.target as HTMLElement);
	}
	onSubmit() {
		let errors = 0;
		for (const field of Array.from(this.el.querySelectorAll('.input-validation:not(.is-hidden)'))) {
			if (!this.validateInput(field as HTMLElement)) {
				errors += 1;
			}
		}

		if (errors) {
			this.options.scrollToInput && this.onFormValidationFail();
			return;
		}

		return true;
	}

	validateInput(container: HTMLElement) {
		this.clearError(container);

		let validateType = container.dataset.validate || '';
		const field = container.querySelector(".input-field") as HTMLInputElement;
		let value = '';

		if(field) {
			value = field.value;
		}
	
		let ok = false;
        let showGlobalErrorFlag = false;

		switch (validateType) {
			case "email":
				ok = (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
				break;
			case "checkbox":
				ok = field.checked;
				break;
			case "date":
				showGlobalErrorFlag = true;
				const dates: HTMLSpanElement[] = Array.from(this.el.querySelectorAll('[data-date]'));
				for (const date of dates) {
					if (date.classList.contains('selected')) {
						ok = true;
					  break;
					}
				  }
				break;
			case "group":
				showGlobalErrorFlag = true;
				const fields: HTMLInputElement[] = Array.from(this.el.querySelectorAll(".input-field"));
				for (const input of fields) {
					if (input.checked) {
						ok = true;
					  break;
					}
				  }
				break;	
			default:
				ok = true;
		}

		if (!ok) {
			if(this.options.showError) {
				this.showError(container);
			}
			
			if(this.options.showGlobalErrorFlag && showGlobalErrorFlag) { 
				this.showGlobalError(container.dataset.error || ''); 
			}

			container.classList.add("validation-fail");
			const f = this.el.querySelector(".validation-fail .input-field") as HTMLInputElement;
			f && f.focus();
		}

		return ok;
	}

	clearError(container: HTMLElement) {
		if (!container.querySelector(".error-container")) {
			// container.appendChild(makeElement("div", "error-container"));
			return;
		}

		container.classList.remove("validation-fail");
		const error = container.querySelector(".error-container");
		if(error) {
			error.innerHTML = "";
		}
	}

	showError(container: HTMLElement) { //error: string
		container.classList.add("validation-fail");
		// const templateClone = this.errorTemplate.cloneNode(true);
		
		// if(!this.options.showGlobalErrorFlag) { 
		// 	container.querySelector(".error-container").insertBefore(templateClone.querySelector("." + error), null); 
		// }
	}

	async showGlobalError(message: string, errorContainerClass = ".global-error"){
		if (this.context.querySelector(".global-error-container") || !message) {
			return;
		}

		// this.context.appendChild(makeElement("div", "global-error-container"));
		this.context.insertBefore(makeElement("div", "global-error-container"), this.context.querySelector(".button-wrapper"));

		const container = this.context.querySelector(".global-error-container");
		const templateClone: any = this.errorTemplate.cloneNode(true);
		container.insertBefore(templateClone.querySelector(errorContainerClass), null);

		container.querySelector(".error-description").innerHTML = message;
		container.querySelector(".error-close-button").addEventListener("click", this.hideGlobalError.bind(this));

		await sleep(300);
    	container.classList.add("is-shown");
		await sleep(400);
    	this.cycle();
	}

	async hideGlobalError(animated = true) {
		clearInterval(this._interval);
		this._interval = null;

		const el = this.context.querySelector(".global-error-container");

		el && el.classList.add("is-removed");
		animated && await sleep(500);
		el && el.parentNode && el.parentNode.removeChild(el);
	}

	cycle(event?: Event) {
        if (!event) {
            this._isPaused = false;
        }

        if (this._interval) {
            clearInterval(this._interval);
            this._interval = null;
        }

        if (Default.interval && !this._isPaused) {
            this._interval = setInterval(
              this.hideGlobalError.bind(this),
              Default.interval
            );
        }
	}

	onFormValidationFail() {
		const element = this.el.querySelector(".validation-fail");
		element && window.scrollTo(0, element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 200);
	}


}
