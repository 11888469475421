import skeleton from '../components/skeleton';
import { IAttraction, IPass, IPassFullModel, ITimeslot } from '../types/main';
import { cultureFormattedDate, dateIsExpired, expiryDate, passIsOngoing, repeatFunction, t } from "../utils/utils";

export interface IBooking {
	id?: string;
	bookingId?: string;
	attraction: IAttraction;
	quantity?: number;
	startDate: string;
	startTime?: string | null;
	endTime?: string;
	passtypes?: string[];
	reservationId?: string;
	barcodes: string[];
	nicknames: INickname[];
	freeTickets: number;
}

export interface INickname {
	barCode: string;
	expiryDate: string;
	nickname: string;
	passTierId: string;
	isFreeTicket: boolean;
}

export interface ITicket {
	attraction: IAttraction;
	barCode: string;
	passType: string;
	passTier: string;
	expiryDate: string;
	barcodeSVG?: string;
	nickname: string;
	startDate: string;
	startTime: string;
	isFreeTicket: boolean;
}


export const renderBookingSummary = (booking: IBooking, isEditable?: boolean) => {
	let nicknames = '';
	if(booking.nicknames.length > 0) {

		let filteredNicknames = booking.nicknames.filter(x => !x.isFreeTicket);

		if(filteredNicknames[0].nickname) {
			nicknames = filteredNicknames.map(obj => obj.nickname).join(', ');
		} else {
			nicknames = filteredNicknames.join(', ');
		}
	}

	if(booking.freeTickets > 0) {
		const under3PassLabel = booking.freeTickets === 1 ? t.label.under3Ticket : t.label.under3TicketPlural;
		nicknames += ` & ${booking.freeTickets} ${under3PassLabel}`
	}
	return `
		<div class="ticket-container booking-status-box">
			<div class="ticket-row booking-status-box__button info-button">
				${isEditable ? `
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="12" cy="12" r="8" stroke="#27336F" stroke-width="2" class="hands-off"></circle>
						<path fill-rule="evenodd" clip-rule="evenodd" d="M12 10c.5523 0 1 .4477 1 1v5c0 .5523-.4477 1-1 1s-1-.4477-1-1v-5c0-.5523.4477-1 1-1ZM12 7c.5523 0 1 .4477 1 1s-.4477 1-1 1-1-.4477-1-1 .4477-1 1-1Z"></path>
					</svg>`: ``}
				<div class="ticket-row-text-container" ${isEditable ? 'data-summary-trip' : ''}>
					<strong>${booking.attraction.attractionName}</strong>
					<span>${booking.attraction.description}</span>
				</div>
			</div>
			<div class="ticket-row booking-status-box__button" ${isEditable ? 'data-summary-guests' : ''}>
				${isEditable ? `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="m14.06 9.02.92.92L5.92 19H5v-.92l9.06-9.06ZM17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29Zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75Z"></path>
				</svg>`: ``}
				<div class="ticket-row-text-container">
					<span>Guests</span>
					<strong>${nicknames}</strong>
				</div>
			</div>
			<div class="ticket-row booking-status-box__button" ${isEditable ? 'data-summary-date' : ''}>
				${isEditable ? `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="m14.06 9.02.92.92L5.92 19H5v-.92l9.06-9.06ZM17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29Zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75Z"></path>
				</svg>`: ``}
				<div class="ticket-row-text-container">
					<span>Date</span>
					<strong>${cultureFormattedDate(booking.startDate)}</strong>
					${booking.startTime ? `<strong>${booking.startTime.slice(0,5)}</strong>` : ''}
				</div>
			</div>
		</div>
	`;
}

export const renderAttractionListData = (list: IAttraction[] | null) => {
	const type = 'attraction';
	const errorMessage = t.error[type];

	if(!list) {
		return `
			${repeatFunction(4, () => (
				skeleton({ layout: 'block', size: 'sm' })
			)).join('')}
			<ul class="trip-selection__list">
				<li class="input-group input-validation" data-validate="group" data-error="${errorMessage}">
					<input type="radio" class="input-field" value="" hidden />
				</li>
			</ul>
		`;
	}

	if(list.length === 0) {
		return `
			${t.feedback.noAttractionsFound}
			<ul class="trip-selection__list">
				<li class="input-group input-validation" data-validate="group" data-error="${errorMessage}">
					<input type="radio" class="input-field" value="" hidden />
				</li>
			</ul>
		`;
	}

	return `
		<ul class="trip-selection__list">
			${list.map((item) => renderAttractionListItem(item, errorMessage)).join('')}
		</ul>
	`;
}

export const renderPassListData = (list: IPass[] | null, checkedBarcodes?: string[]) => {
	const type = 'pass';
	const errorMessage = t.error[type];

	if(!list) {
		return `
			${repeatFunction(4, () => (
				skeleton({ layout: 'block', size: 'sm' })
			)).join('')}
			<ul class="pass-selection__list">
				<li class="input-group input-validation" data-validate="group" data-error="${errorMessage}">
					<input type="checkbox" class="input-field" value="" hidden />
				</li>
			</ul>
		`;
	}

	if(list.length === 0) {
		return `
			${t.feedback.noPassesFound}
			<ul class="pass-selection__list">
				<li class="input-group input-validation" data-validate="group" data-error="${errorMessage}">
					<input type="checkbox" class="input-field" value="" hidden />
				</li>
			</ul>
		`;
	}


	return `
		<ul class="pass-selection__list">
			${list.map((item, index: number) => renderPassListItem(item, errorMessage, index, checkedBarcodes)).join('')}
		</ul>
	`;
}

export const renderUnderThreeTicket = () => {
	const id = Math.random().toString(36).slice(2, 7);

	return `
		<li>
			<img src="/dist/assets/gfx/Under_3s_Day_Pass.png" class="trip-selection__list__image" />
			<div class="trip-selection__list__attraction">
				<label for="item-under-three-${id}">
					<strong>${t.label.under3Ticket}</strong>
				</label>
			</div>
			<input type="checkbox" id="item-under-three-${id}" name="pass" value="under 3" data-under-three checked />
		</li>
	`;
}

const renderAttractionListItem = (item: IAttraction, errorMessage: string) => `
	<li class="input-group input-validation" data-validate="group" data-error="${errorMessage}">
		<div class="trip-selection__list__attraction">
			<label for="${item.accessoAttractionId}">
				<strong>${item.attractionName}</strong><br>
				${item.description || ''}
			</label>
		</div>
		<input type="radio" class="input-field" id="${item.accessoAttractionId}" data-attraction-id="${item.merlinAttractionId}" data-allow-free-tickets="${item.allowFreeTickets}" name="attraction" value="${item.attractionName}" data-attraction />
	</li>`;

const renderPassListItem = (item: IPass, errorMessage: string, index: number, checkedBarcodes: string[] = []) => {
	const passHasExpired = dateIsExpired(item.expiryDate);
	const ongoingPass = passIsOngoing(item.expiryDate);
	const expiryLabel = passHasExpired ? t.label.expired : t.label.expire;
	const formattedDateString = ongoingPass ? t.label.ongoing : expiryLabel + ' ' + cultureFormattedDate(item.expiryDate);
	const isChecked = checkedBarcodes.indexOf(item.barCode) > -1;

	return `
		<li class="input-group input-validation ${passHasExpired ? 'disabled' : ''}" data-validate="group" data-error="${errorMessage}">
			<img src="${item.imageUrl}?anchor=center&mode=crop&width=150&height=100" class="trip-selection__list__image" />
			<div class="trip-selection__list__attraction">
				<label for="item-${index}">
					<strong>${item.nickname}</strong><br>
					${formattedDateString}
				</label>
			</div>
			<input type="checkbox" class="input-field" id="item-${index}" name="pass" value="${item.nickname}" data-pass="${item.passTierId}" data-barcode="${item.barCode}" data-expiry-date="${item.expiryDate}" ${passHasExpired ? 'disabled' : ''} ${isChecked ? 'checked' : ''} />
		</li>`
};

export const renderPassFullItem = (item: IPassFullModel) => {
	const passHasExpired = dateIsExpired(item.expiryDate);
	const ongoingPass = passIsOngoing(item.expiryDate);
	const expiryLabel = passHasExpired ? t.label.expired : t.label.expire;
	const formattedDateString = ongoingPass ? t.label.ongoing : expiryLabel + ' ' + cultureFormattedDate(item.expiryDate);

	return `
		<section class="passes-wrapper isolated">
			<ul class="passes-list">
				<li>
					<div class="passes-list__pass-image">
						${item.imageUrl ? `<img src="${item.imageUrl}?anchor=center&mode=crop&width=150&height=100" alt="">` : ''}
					</div>
					<div class="passes-list__pass-name">
						<h3 class="pass-header">${item.nickname}</h3>
					</div>
					<div class="passes-list__pass-expirationdate">${formattedDateString}</div>
				</li>
			</ul>
		</section>
	`
};

export const clearSummaryTicket = (context: HTMLElement) => {
	const wrapper = context.querySelector('.ticket-confirm');

	if(!wrapper) {
		return;
	}

	wrapper.innerHTML = skeleton({ layout: 'block', size: 'lg' });
}

export const generateSummaryTicket = (type: 'create' | 'edit', ticket: IBooking, context: HTMLElement, stepper: any) => {
	if(!ticket) {
		return;
	}

	const wrapper = context.querySelector('.ticket-confirm');

	if(!wrapper) {
		return;
	}

	const render = () => {
		const isEditable = true;
		wrapper.innerHTML = renderBookingSummary(ticket, isEditable);

		// Register event listeners
		const summaryTripEditButton = context.querySelector('[data-summary-trip]');
		const summaryGuestsEditButton = context.querySelector('[data-summary-guests]');
		const summaryDateEditButton = context.querySelector('[data-summary-date]');

		if(summaryTripEditButton && type === 'create') {
			summaryTripEditButton.addEventListener('click', () => stepper.navigate(0));
		}
		if(summaryGuestsEditButton) {
			summaryGuestsEditButton.addEventListener('click', () => stepper.navigate(type === 'create' ? 1 : 0));
		}
		if(summaryDateEditButton) {
			summaryDateEditButton.addEventListener('click', () => stepper.navigate(type === 'create' ? 2 : 1));
		}
	}
	render();
}

export const getArrivalTime = (context: HTMLElement, time: ITimeslot[], callback: (e: Event) => void, amount: number, checked?: string | null) => {
	const wrapper = context.querySelector('.prebook-arrival-time');

	if(!wrapper) {
		return;
	}

	let content = time.length === 0 ? '' : renderArrivalTime(time, amount, checked);

	const render = () => {
		wrapper.innerHTML = content;

		// Register event listeners
		const arrivalTimes = Array.from(context.querySelectorAll('[data-start-time]'));
		for (let input of arrivalTimes) {
			input.addEventListener('change', callback);
		}
	}

	render();
}

export const renderArrivalTime = (list: ITimeslot[], amount: number, checked?: string | null) => {
	return `
	<div class="arrival-time">
		<div class="arrival-time__title">${t.headline.arrivalTime}</div>
		<div class="arrival-time__selection">
			<ul class="arrival-time__list">
				${list.map((item, i) => {
					let available = item.available;
					const time = `
						<div>${item.startTime}</div>
						<div>${item.endTime ? 'to' : ''}</div>
						<div>${item.endTime || ''}</div>
					`;
					if(item.available == '' || item.available == null) {
						available = '0';
					}
					if(parseInt(available) >= amount) {
						return `
						<li>
							<div class="arrival-time__list__item input-validation" data-validate="group" data-error="${t.error.arrivalTime}">
								<input class="input-field" type="radio" id="arrival-time-${i}" name="arrival-time" value="" data-start-time="${item.startTime || ''}" data-end-time="${item.endTime || ''}" ${checked && checked.slice(0,5) === item.startTime ? 'checked' : ''}>
								<label for="arrival-time-${i}">
									<div>${time}</div>
								</label>
							</div>
						</li>
				`}}).join('')}
			</ul>
		</div>
	</div>`;
}

export const renderTicketsData = (tickets: ITicket[] | null) => {
	if(!tickets) {
		return`
		<div class="printable-tickets">
			<div class="printable-tickets-count"><span>0</span> Tickets</div>
			<div class="printable-tickets-scroller">
				${skeleton({ layout: 'block', size: 'xl' })}
			</div>
		</div>`
	}

	return `
		<div class="printable-tickets">
			<div class="printable-tickets-count"><span>${tickets.length}</span> Tickets</div>
			<div class="printable-tickets-scroller">
				${tickets.map((ticket: ITicket) => `
					<div class="printable-tickets-item" data-index="1">
						<div class="logo-data"><img src="${ticket.attraction.imageUrl}?anchor=center&mode=crop&width=260&height=180" alt="Logo"></div>
						<div class="destination-data">
							<div class="small">${ticket.attraction.attractionName}</div>
							<div><strong>${cultureFormattedDate(ticket.startDate)} ${ticket.startTime.slice(0,5)}</strong></div>
						</div>
						${!ticket.isFreeTicket ? `<div class="barcode-data">
							<div class="normal">${ticket.barcodeSVG}</div>
							<div class="normal">${ticket.barCode}</div>
						</div>` : ''}
						<div class="guest-data">
							<div class="small">${ticket.passTier ? t.label.individual : t.label.freeTicket}</div>
							<div><strong>${ticket.nickname}</strong></div>
						</div>
						<div class="pass-data small">
							<div>${ticket.passTier ? ticket.passTier : ''}</div>
							<div>${expiryDate(ticket.expiryDate)}</div>
						</div>
						<div class="separator"></div>
					</div>
				`).join('')}
			</div>
		</div>
	`;
}