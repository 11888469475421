import { addPass } from '../api/umbraco-api';
import DialogViewBase, { ButtonVariation, IDialogOptions } from '../core/dialog-view-base';
import { makeElement, sleep, t } from '../utils/utils';
import Notification from '../components/notification';
import { endButtonLoadingState, startButtonLoadingState } from '../utils/animations';
import { verifyPass } from '../constants/dialogs';
import { isFetchErrorResponse } from '../types/main';

interface IExtendedDialogOptions extends IDialogOptions {
	onConfirm: () => void;
	onReject: () => void;
	primaryButtonVariation?: ButtonVariation;
	secondaryButtonVariation?: ButtonVariation;
}

export enum PassOwnerType {
	OWNER_PASS = 'OWNER_PASS',
	NEW_PASS = 'NEW_PASS',
  }

export default class AddPassDialog extends DialogViewBase {
	passID: HTMLInputElement | null;
	nickname: HTMLInputElement | null;
	submitButton: HTMLButtonElement;
	constructor(options: IExtendedDialogOptions) {
		super(options);
		this.onSubmit = this.onSubmit.bind(this);
	}

	registerEventListeners() {
		const form = this.el.querySelector('form');
		form && form.addEventListener('submit', this.onSubmit);

		this.submitButton = this.el.querySelector('.button') || makeElement("button", "next-button");
		this.passID = this.el.querySelector('input[name="passID"]');
		this.nickname = this.el.querySelector('input[name="nickname"]');

		this.passID && this.passID.addEventListener('input', (e: Event) => {
			if (e.target) {
				const tr = e.target as HTMLInputElement;
				tr.value = tr.value.replace('e', '');
				tr.value = tr.value.replace('-', '');
				tr.value = tr.value.replace('+', '');
				tr.value = tr.value.replace(',', '');
				tr.value = tr.value.replace('.', '');

				if (tr.value.length > tr.maxLength) {
					tr.value = tr.value.slice(0, tr.maxLength);
				}
			}
		});
	}

	async onSubmit(event: Event) {
		event.preventDefault();

		const barcode = this.passID ? this.passID.value : '';
		const nickname = this.nickname ? this.nickname.value : '';

		startButtonLoadingState(this.submitButton);

		try {
			const response: PassOwnerType = await addPass({
				barcode,
				nickname,
			});

			if (isFetchErrorResponse(response)) {
				throw response;
			}


			if(this.options.onConfirm) {
				this.options.onConfirm();
			}

			const title = response === PassOwnerType.OWNER_PASS ? t.headline.ownedPass : t.headline.newPass;
			const message = response === PassOwnerType.OWNER_PASS ? t.description.ownedPass : t.description.newPass;

			verifyPass(title, message);

		} catch (error) {
			Notification.add(error.errorMessage || error.detail);
			endButtonLoadingState(this.submitButton);
		}

		await sleep(400);

		this.remove();
	}

	render() {
		const wrapper = this.el.querySelector('.dialog-content-wrapper');

		if(!wrapper) {
			return;
		}
		
		wrapper.innerHTML = `
			<div class="add-pass-dialog-wrapper">
				<div class="content">
					<h3>${this.options.title}</h3>
					<p>${this.options.message}</p>
					<p>${t.description.registerPassNote}</p>
					<form class="form" id="add-pass-form">
						<div class="textFieldContainer">
							<label class="label" for="passID">
								${t.label.passId}
								<span class="star">*</span>
							</label>
							<div class="textFieldWrapper">
								<input autocomplete="off" class="textField" id="passID" name="passID" pattern="\d*" type="number" min="0" max="999999999999999999" minlength="18" maxlength="18" value="" required />
							</div>
						</div>
						<div class="textFieldContainer">
							<label class="label" for="nickname">
								${t.label.passName} 
								<span class="star">*</span>
							</label>
							<div class="textFieldWrapper">
								<input autocomplete="off" class="textField" id="nickname" name="nickname" placeholder="${t.placeholder.passName}" required type="text" value="" />
							</div>
						</div>
					</form>
				</div>
				<div class="bottom-panel-wrapper">
					<button class="button button--primary" type="submit" form="add-pass-form"><span>${t.button.addNewPass}</span></button>
				</div>
			</div>
		`;

		this.registerEventListeners();
		this.show();
	}
}