import Accordion from './components/Accordion';
import IApp from './core/iapp';
import { cancelTripConfirmation, deletePassConfirmation, editBookingConfirmation, passInfo, registerYourPass, startBooking, viewTickets } from './constants/dialogs';
import { IBooking } from './templates/list-items';
import { IPassFullModel } from './types/main';

class ViewLoader {
	constructor(querySelector: string, load: (el: HTMLElement) => void) {
		this.querySelector = querySelector;
		this.load = load;
	}
	querySelector: string;
	load: (el: HTMLElement) => void;
}

export default class App implements IApp {
	viewLoaders: ViewLoader[] = [
		new ViewLoader('.accordion', (el) => new Accordion(el)),
	];
	el: Element;
	constructor() {
		const el = document.querySelector('#prebook-portal-app');
		
		if(!el) {
			return;
		}

		this.el = el;
		this.init();
	}

	async init() {
		this.initViews();
		this.initBookingsOverviewEvents();
		this.initPassesOverviewEvents();
	}


	initPassesOverviewEvents() {
		const button = this.el.querySelector('[data-manage-passes]');

		if(button) {
			// Register pass flow
			button.addEventListener('click', registerYourPass);
		}

		const passes = Array.from(this.el.querySelectorAll('[data-pass]'));

		if (passes.length) {
			for (let el of passes) {
				const pass = JSON.parse(el.getAttribute('data-pass') || '');
				this.deletePassHandler(el, pass);
				this.infoPassHandler(el, pass);
			}
		}
	}

	initBookingsOverviewEvents() {
		const button = this.el.querySelector('[data-book-trip]');

		if(button) {
			// Add booking flow
			button.addEventListener('click', startBooking);
		}	

		const bookings = Array.from(this.el.querySelectorAll('[data-booking]'));

		if (bookings.length) {
			for (let el of bookings) {
				const booking = JSON.parse(el.getAttribute('data-booking') || '');
				this.editBookingHandler(el, booking);
				this.deleteBookingHandler(el, booking);
				this.viewTicketsHandler(el, booking);
			}
		}
	}

	editBookingHandler(el: Element, booking: IBooking) {
		const buttons = Array.from(el.querySelectorAll('[data-edit-booking]'));
		
		if (buttons.length) {
			for (let button of buttons) {
				button.addEventListener('click', () => editBookingConfirmation(booking));	
			}
		}
	}

	deleteBookingHandler(el: Element, booking: IBooking) {
		const buttons = Array.from(el.querySelectorAll('[data-delete-booking]'));
		
		if (buttons.length) {
			for (let button of buttons) {
				button.addEventListener('click', () => cancelTripConfirmation(booking));	
			}
		}
	}

	deletePassHandler(el: Element, pass: IPassFullModel) {
		const buttons = Array.from(el.querySelectorAll('[data-delete-pass]'));
		
		if (buttons.length) {
			for (let button of buttons) {
				button.addEventListener('click', () => deletePassConfirmation(pass));	
			}
		}
	}

	infoPassHandler(el: Element, pass: IPassFullModel) {
		const buttons = Array.from(el.querySelectorAll('[data-pass-info]'));
		
		if (buttons.length) {
			for (let button of buttons) {
				button.addEventListener('click', () => passInfo(pass));	
			}
		}
	}

	viewTicketsHandler(el: Element, booking: IBooking) {
		const buttons = Array.from(el.querySelectorAll('[data-view-ticket]'));

		if (buttons.length) {
			for (let button of buttons) {
				button.addEventListener('click', () => viewTickets(booking));
			}
		}
	}

	initViews() {
		for (const loader of this.viewLoaders) {
			for (const node of this.el.querySelectorAll(loader.querySelector) as any) {
				loader.load(node as HTMLElement);
			}
		}
	}
}