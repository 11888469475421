import DialogViewBase, { ButtonVariation, IDialogOptions } from '../core/dialog-view-base';
import { ITicket, renderTicketsData } from '../templates/list-items';
import { isFetchErrorResponse } from '../types/main';
import { sleep, t } from '../utils/utils';
import Notification from '../components/notification';
import { getTickets } from '../api/umbraco-api';

interface IExtendedDialogOptions extends IDialogOptions {
	onConfirm: () => void;
	onReject: () => void;
	primaryButtonVariation?: ButtonVariation;
	secondaryButtonVariation?: ButtonVariation;
}

export default class ViewTicketsDialog extends DialogViewBase {
	bookingId: string;
    primaryButton: HTMLButtonElement | null;
    tickets: ITicket[];
	constructor(bookingId: string, options: IExtendedDialogOptions) {
		super(options);
		this.bookingId = bookingId;
        this.handleConfirm = this.handleConfirm.bind(this);

        this.fetchTicketData();
	}

	async fetchTicketData() {
		if(!this.bookingId) {
			return;
		}

		try {
			const response: ITicket[] = await getTickets(this.bookingId);

			if (isFetchErrorResponse(response)) {
				throw response;
			}

			this.tickets = response;
			
		} catch (error) {
			this.tickets = [];
			Notification.add('We could not load your ticket. Try again in a few minutes...');
		}

		await sleep(500);

        this.render();

	}

	registerEventListeners() {
        this.primaryButton = this.el.querySelector('[data-primary-button]');
		
		if(this.primaryButton) {
			this.primaryButton.addEventListener('click', this.handleConfirm);
		}

        // const observableTickets = Array.from(this.el.querySelectorAll('.printable-tickets-item'));
        const ticketCount = this.el.querySelector('.printable-tickets-count > span') as HTMLSpanElement;
        ticketCount.textContent = `${this.tickets ? this.tickets.length : 0}`;
        //if ('IntersectionObserver' in window) {
        //    ticketCount.textContent = `1/${observableTickets.length}`;
        //    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {

        //        entries.forEach((entry: IntersectionObserverEntry) => {
        //            console.log('target: ', (entry.target as HTMLDivElement));
        //            console.log('target index: ', (entry.target as HTMLDivElement).dataset.index);
        //            console.log('intersectionRatio: ', entry.intersectionRatio);
        //            console.log('rootBounds: ', entry.rootBounds);
        //            console.log('isIntersecting: ', entry.isIntersecting);
        //            if (entry.intersectionRatio > 0) {
        //                ticketCount.textContent = `${(entry.target as HTMLDivElement).dataset.index}/${observableTickets.length}`;
        //            }
        //        });

        //    }, {
        //        root: this.el.querySelector('.printable-tickets-scroller') as HTMLDivElement,
        //        rootMargin: '0px',
        //        threshold: 1.0,
        //    });

        //    observableTickets.forEach((ticket: any) => {
        //        observer.observe(ticket);
        //    });
        //}

        //window.addEventListener('resize', () => {
        //    observableTickets[0].scrollIntoView({ behavior: 'smooth', inline: 'start' })
        //});
	}

    async handleConfirm() {
		if(this.options.onConfirm) {
			await this.options.onConfirm();
		}
		this.remove();
	}

	render() {
		const wrapper = this.el.querySelector('.dialog-content-wrapper');

		if(!wrapper) {
			return;
		}

		wrapper.innerHTML = `
			<div class="content view-tickets-dialog-wrapper">
				<div class="prebook">
					<h3 class="view-tickets-dialog-wrapper__headline">${this.options.title}</h3>
					<p>Booking ID: ${this.bookingId}</p>
                </div>
                <section>
                    <div class="grid grid--max-width" data-tickets>
                        ${renderTicketsData(this.tickets)}
                    </div>
                </section>

                <div class="prebook">
					<div class="button-wrapper">
						<button class="button button--primary next-button" data-primary-button><span>${t.button.bookingOverView}</span></button>
					</div>
				</div>
			</div>
		`;

		this.registerEventListeners();
		this.show();
	}
}
