import { ButtonVariation, IDialogOptions } from '../core/dialog-view-base';
import { renderPassFullItem } from '../templates/list-items';
import { IPassFullModel } from '../types/main';
import DialogView from './dialog-view';

export interface IExtendedDialogOptions extends IDialogOptions {
	pass: IPassFullModel;
	onConfirm: () => void;
	onReject: () => void;
	secondaryButtonVariation?: ButtonVariation;
	secondaryButtonText?: string;
}

export default class PassInfoDialog extends DialogView {
	constructor(options: IExtendedDialogOptions) {
		super(options);
	}

	render() {
		const wrapper = this.el.querySelector('.dialog-content-wrapper');

		if(!wrapper) {
			return;
		}
		
		wrapper.innerHTML = `
			<div class="add-pass-dialog-wrapper">
				${this.options.pass ? renderPassFullItem(this.options.pass) : ''}
				<div class="content">
					<h3>${this.options.title}</h3>
					${this.options.message ? `<p>${this.options.message}</p>` : ''}
				</div>
				<div class="bottom-panel-wrapper">
					${this.options.secondaryButtonText ? `<button class="button ${this.computeButtonVariation(this.options.secondaryButtonVariation || 'secondary')}" data-secondary-button><span>${this.options.secondaryButtonText}</span></button>` : ''}
				</div>
			</div>
		`;

		this.registerEventListeners();
		this.show();
	}
}
